import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import ContactForm from "./contactForm"

const Layout = ({ children }) => {

  return (
    <div className="flex flex-col min-h-screen  font-outfit bg-secondary">
      <Header />
      <main className="min-h-full mt-14 md:mt-0">{children}</main>
      {/* For netlify, form needs to be available at build time, dynamically rendered form is not recognised */}
      <div className="hidden"> 
        <ContactForm />
      </div>
      <Footer className="container mx-auto" />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
