import React from 'react'
import ContactModal from './contactModal'

export default function footer() {
    return (
        <footer className="container mx-auto md:mt-8 pb-4">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-16 my-4 md:mt-12 xl:mt-0 border-t border-tertiary pt-4 md:pt-16">
                <div className='text-left col-span-2'>
                    <h3 class="text-xl md:text-5xl font-playFair md:mb-12">Kick-start your build with us</h3>
                    <ContactModal type="text"/>
                </div>

                <div class="hidden lg:justify-end lg:flex flex-col gap-4 text-left">
                    <div class="lg:justify-end xl:mt-0">
                        <h2 class="text-2xl font-playFair">Location, UAE</h2>
                        <a href="https://goo.gl/maps/weBF3WpeNgjsdGRz9" target="_blank" class="mt-4 text-sm">Dubai Investment Park 1, Street 43 Community 598, Opp - Green Community West - Dubai</a>
                    </div>

                    <div class="lg:justify-end xl:mt-0">
                        <p class="text-sm">Email us at</p>
                        <a href="mailto:websales@livethedream.ae" class="font-playFair text-primary text-2xl">websales@livethedream.ae</a>
                    </div>

                    <div class="lg:justify-end xl:mt-0">
                        <p class="text-sm">If you're in a hurry, quick call for us</p>
                        <a href="tel:0588934020" class="font-playFair text-primary text-2xl">058 893 4020</a>
                    </div>
                </div>
            </div>
            <div class="text-sm mt-8 text-center w-full">© 2023 Live The Dream</div>
        </footer>
    )
}
