import React from "react";
import { FiArrowUpRight } from "@react-icons/all-files/fi/FiArrowUpRight";
import ContactForm from "./contactForm";
import { StaticImage } from "gatsby-plugin-image";

export default function ContactModal({ type }) {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <div>
            {type === "cta" ?
                <button onClick={() => setShowModal(true)}>
                    <StaticImage class="z-30 w-30 h-30 ml-24 -mb-14" src='../images/CTAbutton.png' alt="" />
                </button>
                :
            type === "text" ?
                <button
                    onClick={() => setShowModal(true)}
                    className='text-left w-full text-xl md:text-5xl font-playFair underline italic text-primary cursor-pointer'
                >
                    Send us a message
                </button>    
                :
                <button
                    className="text-white flex p-3 bg-primary uppercase   hover:bg-transparent border border-primary hover:text-primary active:text-primary ease-linear transition-all duration-150 mx-auto md:mx-0"
                    type="button"
                    onClick={() => setShowModal(true)}
                >
                    <span className="text-xs px-2">Contact Us </span> <FiArrowUpRight />
                </button>
            }

            {showModal ? (
                <div className="ease-linear transition-all duration-150 z-40">
                    <div
                        onClick={() => setShowModal(false)}
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full lg:w-1/2 2xl:w-1/3 mx-auto ">

                            <div
                                onClick={e => e.stopPropagation()}
                                className="relative flex flex-col w-full bg-black text-black outline-none focus:outline-none">
                                <button
                                    type="button"
                                    class="text-white hover:text-tertiary p-4 ml-auto inline-flex items-center "
                                    onClick={() => setShowModal(false)}
                                >
                                    <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </button>
                                <ContactForm />

                            </div>
                        </div>
                    </div>
                    <div className="opacity-50 fixed inset-0 z-40 bg-black" onClick={() => setShowModal(false)}></div>
                </div>
            ) : null}
        </div>
    );
}