// import { graphql, useStaticQuery, Link } from "gatsby";
import { Link } from "gatsby";
import React, { useState } from "react";
import logo from "../images/logo-sign.png"
import ContactModal from "./contactModal";
import { CgMenu } from '@react-icons/all-files/cg/CgMenu'
import { CgClose } from '@react-icons/all-files/cg/CgClose'
import { StaticImage } from "gatsby-plugin-image";
// import FaHeart from "@react-icons/all-files/fa/FaHeart"

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header>
      <div className="fixed bg-white z-40 border-b border-black md:border-transparent md:relative container font-outfit flex flex-wrap items-center justify-between mx-auto py-4 md:py-8">

        <Link to="/" className="flex gap-3">
          <StaticImage
            alt="Live The Dream Logo"
            className="block mx-auto w-6 h-6 lg:w-8 lg:h-8 2xl:w-9 2xl:h-9"
            src="../images/logo-sign.png"
          />
          <span className="font-westMount text-sm md:text-lg 2xl:text-xl">Live The Dream</span>
        </Link>

        <button
          className="items-center block rounded md:hidden text-2xl"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          {isExpanded ? <CgClose /> : <CgMenu />}

        </button>

        <nav
          className={`${isExpanded ? `block` : `hidden`
            } md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: '/inspiration',
              title: 'Inspiration',
            },
            {
              route: '/services',
              title: 'Services'
            },
            {
              route: '/facilities',
              title: 'Facilities',
            },
            {
              route: '/projects',
              title: 'Projects'
            },
            {
              route: `/about`,
              title: `About`,
            }
          ].map((link) => (
            <Link
              className="text-center hover:text-primary block mt-4 no-underline md:inline-block md:mt-0 md:ml-4 lg:ml-6 text-sm md:text-base"
              activeClassName="text-primary"
              key={link.title}
              to={link.route}
              partiallyActive={true}
            >
              {link.title}
            </Link>
          ))}
          <div className="text-center block mt-4 no-underline md:inline-block md:mt-0 md:ml-4 lg:ml-6" >
            <ContactModal />
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
