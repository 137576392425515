import React from 'react'
import { FiArrowUpRight } from "@react-icons/all-files/fi/FiArrowUpRight";

export default function ContactForm() {

    return (
        <form
            name="contact" 
            method="POST" 
            netlify
            data-netlify="true"
            netlify-honeypot="bot-field"
            class="py-10 sm:px-4 md:px-12 2xl:px-32 text-white text-left"
            action='/'
        >
            <input type="hidden" name="bot-field" /> {/*For bots - filtered as spam*/}
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="subject" value="LTD site contact form submission"  /> {/*email subject for netlify*/}
            <h3 className="text-center font-playFair text-2xl md:text-3xl 2xl:text-4xl mb-4">Contact Us</h3>
            <div class="w-full mb-4 px-4 text-xs">
                <label class="block mb-3 "> Name </label>
                <input
                    name='name'
                    type="text"
                    required="true"
                    class="block w-full mb-4 px-4 text-xs py-2.5  text-[#616161] placeholder-[#616161] placeholder-opacity-75 border-primary border-2 bg-white transition focus:bg-gray-200 focus:outline-none"
                    aria-label="Input"
                    placeholder="First & Last Name" 
                />
            </div>

            <div class="w-full mb-4 px-4 text-xs">
                <label class="block mb-3 "> Email </label>
                <input
                    name='email'
                    type="email"
                    required="true"
                    class="block w-full mb-4 px-4 text-xs py-2.5  text-[#616161] placeholder-[#616161] placeholder-opacity-75 border-primary border-2 bg-white transition focus:bg-gray-200 focus:outline-none"
                    aria-label="Input"
                    placeholder="Email@website.com" 
                />
            </div>

            <div class="w-full mb-4 px-4 text-xs">
                <label class="block mb-3 "> Phone </label>
                <input
                    name='phone'
                    type="text"
                    class="block w-full mb-4 px-4 text-xs py-2.5  text-[#616161] placeholder-[#616161] placeholder-opacity-75 border-primary border-2 bg-white transition focus:bg-gray-200 focus:outline-none"
                    aria-label="Input"
                    placeholder="000-000-0000" 
                />
            </div>

            <div class="w-full mb-4 px-4 text-xs">
                <label class="block mb-3 "> Message </label>
                <textarea
                    name='message'
                    class="block w-full mb-4 px-4 text-xs h-20 max-h-48 py-2.5  text-[#616161] placeholder-[#616161] placeholder-opacity-75 border-primary border-2 bg-white transition focus:bg-gray-200 focus:outline-none resize-vertical"
                    aria-label="Textarea"
                    required="true"
                    placeholder="Type your message here"></textarea>
            </div>

            <button
                className="mx-auto text-white flex p-3 bg-primary uppercase   hover:bg-transparent border border-primary hover:text-primary active:text-primary ease-linear transition-all duration-150"
                type="submit"
            >
                <span className="text-xs px-2">Send </span> <FiArrowUpRight />
            </button>

        </form>
    )
}
